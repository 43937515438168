<template>
  <div>
    <div class="box-setting-header index-style-user">
      <div class="title ml-4 mt-2">Gérer les configurations</div>
      <div>
        <b-button
          v-show="selectedMenu == 'config' && checkPermission('GLCTHAC')"
          variant="success"
          class="config-btn ml-2"
          @click="openModalConfig"
          v-b-modal.addConfigurationFraisModal
        >
          <font-awesome-icon icon="plus" /> Ajouter une configuration</b-button
        >
        <b-button
          v-show="selectedMenu == 'famille' && checkPermission('GDFCAUF')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addConfigurationFamilleFraisModal
          ><font-awesome-icon icon="plus" /> Ajouter une famille</b-button
        >
      </div>
      <b-modal
        ref="addConfigurationFraisModal"
        id="addConfigurationFraisModal"
        :hide-footer="true"
        :hide-header="true"
        @hidden="resetModal()"
        no-close-on-backdrop
        modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
      >
        <div class="hader mb-2">
          <div class="titleSetting">Ajouter une configuration</div>
          <div
            class="iconClose"
            @click.prevent="hideModal('addConfigurationFraisModal')"
          >
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="handleSubmitConfiguration"
              class="form-modal-custom-style"
            >
              <div class="actionModel">
                <div class="messageError">
                  <div v-if="getConfigurationError" class="error">
                    <ul
                      v-if="Array.isArray(getConfigurationError)"
                      class="mb-0"
                      style="list-style-type: none"
                    >
                      <li
                        v-for="(error, index) in getConfigurationError"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                    <div v-else>{{ getConfigurationError }}</div>
                  </div>
                  <div v-if="getErrorForSpecific" class="error">
                    <div class="d-flex flex-row justify-content-center">
                      <p>{{ getErrorForSpecific.error }}</p>
                      <div class="ml-2 bckground-icon-info">
                        <font-awesome-icon
                          class="info-icon"
                          :id="'tooltip-societe'"
                          icon="info"
                        />
                      </div>
                    </div>
                    <b-tooltip :target="'tooltip-societe'" triggers="hover">
                      <ul class="mb-0" style="list-style-type: none">
                        <li
                          v-for="(error,
                          index) in getErrorForSpecific.acheteurs"
                          :key="index"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </b-tooltip>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8">
                  <div class="ModelCol mr-3">
                    <b-col sm="2" md="2">
                      <b-form-group
                        label="Famille : "
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                      </b-form-group
                    ></b-col>
                    <b-col sm="2" md="2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.main_family"
                          @change="initialFamilly"
                          name="some-radios"
                          value="support"
                          class="check-th-details mt-2 mb-2"
                          >Support</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>

                    <b-col sm="2" md="2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.main_family"
                          name="some-radios"
                          value="master"
                          @change="changeInitialFamily"
                          class="check-th-details mt-2 mb-2"
                          >Master</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                    <b-col sm="2" md="2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.main_family"
                          @change="
                            initialFamilly;
                            configurationToAdd.filiale_id = 291;
                          "
                          name="some-radios"
                          value="commercial"
                          class="check-th-details mt-2 mb-2"
                          >Commercial</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                  </div>
                  <div
                    class="ModelCol mr-3"
                    v-if="configurationToAdd.main_family == 'commercial'"
                  >
                    <div class="col-lg-8">
                      <b-form-group
                        class="famillies-list-label"
                        label-cols-sm="6"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="8"
                        label="Commercial *  : "
                      >
                        <multiselect
                          v-model="configurationToAdd.commercials"
                          :options="computedCommercial"
                          :multiple="true"
                          label="name"
                          track-by="id"
                          placeholder="sélectionner un commercial"
                          :showLabels="false"
                          class="multiselect-vue-custom-style-theme"
                          :loading="loadingFiliale"
                        >
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="col-lg-2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          name="flavour-1"
                          id="checkbox-group-filter"
                          class="check-th"
                          @change="SelectAllCommercials($event)"
                          :checked="
                            computedSelectAllComercials(
                              configurationToAdd.commercials
                            )
                          "
                        >
                          Tous
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div
                    class="ModelCol mr-3"
                    v-if="configurationToAdd.main_family == 'support'"
                  >
                    <div class="col-lg-8">
                      <b-form-group
                        class="famillies-list-label"
                        label-cols-sm="6"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="8"
                        label="Famille Support: "
                      >
                        <multiselect
                          v-model="configurationToAdd.famillies"
                          :options="getListFamilleConfigFrias"
                          :multiple="true"
                          label="familly"
                          track-by="id"
                          placeholder="sélectionner une famille"
                          required
                          :showLabels="false"
                          :loading="getLoadingFamilleConfig"
                          class="multiselect-vue-custom-style-theme"
                        >
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="col-lg-2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          name="flavour-1"
                          id="checkbox-group-filter"
                          class="check-th"
                          @change="SelectAllFamilles($event)"
                          :checked="
                            computedSelectAllFamilles(
                              configurationToAdd.famillies
                            )
                          "
                        >
                          Tous
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div class="col-lg-2">
                      <b-form-group
                        label=""
                        label-for=""
                        class="input-modal-champ mb-0"
                      >
                        <button
                          type="button"
                          v-b-modal.addConfigurationFamilleFraisModal
                          class="ml-2 btn-add-anay-prop mt-2"
                          title="Ajouter une famille"
                        >
                          <font-awesome-icon
                            icon="plus"
                            class="icon-plus-ciston-config-dync"
                          />
                        </button>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="m-0"><hr /></div>
                  <div class="ModelCol mr-3">
                    <b-col sm="3" md="3">
                      <b-form-group
                        label="Regroupement par : "
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                      </b-form-group
                    ></b-col>
                    <b-col sm="2" md="2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.grouped"
                          name="some-radios"
                          @change="changeState"
                          value="dossier"
                          class="check-th-details mt-2 mb-2"
                          >Par dossier</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                    <b-col sm="2" md="2">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.grouped"
                          name="some-radios"
                          value="groupe"
                          class="check-th-details mt-2 mb-2"
                          @change="stateInitial"
                          >Par groupe</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                  </div>
                  <div
                    class="ModelCol mr-3"
                    v-if="configurationToAdd.grouped == 'groupe'"
                  >
                    <b-form-group
                      label="Grouper par"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        :value="configurationToAdd.group_by"
                        :options="computedGetColumns"
                        placeholder="Groupe par"
                        @changeValue="configurationToAdd.group_by = $event"
                        label="value"
                        trackBy="key"
                        noResult="Aucun option trouvé."
                      />
                    </b-form-group>
                    <!-- bureau d'etude -->
                    <b-form-group
                      label="Facture grouper par :"
                      label-for="be-name"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        :value="configurationToAdd.facture_group_by"
                        :options="computedGetColumns"
                        placeholder="Facture groupe par"
                        @changeValue="
                          configurationToAdd.facture_group_by = $event
                        "
                        label="value"
                        trackBy="key"
                        noResult="Aucun option trouvé."
                      />
                    </b-form-group>
                  </div>
                  <div class="m-0"><hr /></div>
                  <div
                    class="ModelCol mr-3"
                    v-if="configurationToAdd.main_family == 'commercial'"
                  >
                    <b-col sm="2" md="2">
                      <b-form-group
                        label="Appel à paiement : "
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                      </b-form-group
                    ></b-col>
                    <b-col sm="3" md="3">
                      <b-form-group
                        label=""
                        label-for="month"
                        class="input-modal-champ type-doc-class"
                      >
                        <b-form-checkbox
                          v-model="configurationToAdd.payment_call"
                          name="some-radios"
                          class="check-th-details mt-2 mb-2"
                          >Appel à paiement</b-form-checkbox
                        >
                      </b-form-group>
                    </b-col>
                    <b-col sm="7" md="7"></b-col>
                  </div>
                  <div
                    class="m-0"
                    v-if="configurationToAdd.main_family == 'commercial'"
                  >
                    <hr />
                  </div>

                  <div class="ModelCol mr-3">
                    <b-form-group
                      :label="'Société à configurer '"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0"
                    >
                      <select-simple-with-search
                        v-if="configurationToAdd.main_family != 'commercial'"
                        :value="configurationToAdd.filiale_id"
                        :options="computedNotCommercial"
                        placeholder="sélectionner une Sociéte"
                        @changeValue="configurationToAdd.filiale_id = $event"
                        :disabled="
                          configurationToAdd.main_family == 'commercial'
                            ? true
                            : false
                        "
                        :refreshValue="true"
                      />
                      <b-form-select
                        v-else
                        autocomplete="off"
                        id="filiale-id-add"
                        v-model="configurationToAdd.filiale_id"
                        value-field="id"
                        text-field="name"
                        :options="computedNotCommercial"
                        class="b-form-select-raduis"
                        disabled
                      ></b-form-select>
                      <div
                        v-if="computedCheckSocieteSupport"
                        class="warning-week"
                      >
                        <font-awesome-icon class="info-icon" icon="info" />
                        cette sociéte n'est pas une société support, aucune
                        facture ne va pas être générer avec cette configuration
                      </div>
                    </b-form-group>
                    <!-- bureau d'etude -->
                    <b-form-group
                      label="Type"
                      label-for="be-name"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="be-name"
                        v-model="configurationToAdd.acheteur_type"
                        value-field="id"
                        text-field="name"
                        :options="acheteur_type_list"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <!-- getFilialsSupport -->
                    <b-form-group
                      label="Client"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0"
                      v-if="configurationToAdd.acheteur_type == 'frais'"
                    >
                      <select-simple-with-search
                        :value="configurationToAdd.acheteur_id"
                        :options="computedNotCommercial"
                        placeholder="sélectionner un client"
                        @changeValue="
                          configurationToAdd.acheteur_id = $event;
                          checkErrorsForAdd();
                        "
                        noResult="Aucun Client trouvé."
                      />
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Début de configuration"
                      label-for="Début de configuration"
                      class="input-modal-champ mb-0 pl-0"
                    >
                      <date-picker
                        format="DD-MM-YYYY"
                        value-type="format"
                        type="date"
                        required
                        v-model="configurationToAdd.date_debut"
                        :clear-button="true"
                        @input="checkErrorsForAdd"
                        :disabled-date="disabledStartDate"
                      ></date-picker>
                    </b-form-group>
                    <b-form-group
                      label="Fin du configuration"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0"
                    >
                      <date-picker
                        format="DD-MM-YYYY"
                        value-type="format"
                        type="date"
                        v-model="configurationToAdd.date_fin"
                        @change="checkErrorsForAdd"
                        :clear-button="true"
                        :disabled-date="disabledEndDate"
                      ></date-picker>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="TVA"
                      label-for="TVA"
                      class="input-modal-champ mb-0 col-4 pl-0"
                    >
                      <b-form-select
                        id="tva"
                        v-model="configurationToAdd.tva"
                        required
                        :options="getAllTvas"
                        class="b-form-select-raduis"
                        text-field="valeur"
                        value-field="valeur"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="TVA %"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label=""
                      label-for=""
                      class="input-modal-champ mb-0 col-2"
                    >
                      <button
                        type="button"
                        v-b-modal.addtvaModalConfigFrais
                        class="ml-2 btn-add-anay-prop"
                        title="Ajouter une nouvelle tva"
                      >
                        <font-awesome-icon
                          icon="plus"
                          class="icon-plus-ciston-config-dync"
                        /></button
                    ></b-form-group>
                    <b-form-group
                      label="Mode de calcul"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0 col-6 pl-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="configurationToAdd.coef_type"
                        :options="coef_type_reduction_options"
                        required
                        class="b-form-select-raduis"
                        @change="checkErrorsForAdd"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Description"
                      label-for="desc-config"
                      class="input-modal-champ mb-0"
                    >
                      <b-textarea
                        autocomplete="off"
                        id="desc-config"
                        v-model="configurationToAdd.description"
                        class="b-form-select-raduis"
                      ></b-textarea>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Facture description"
                      label-for="desc-config"
                      class="input-modal-champ mb-0"
                    >
                      <b-textarea
                        autocomplete="off"
                        id="desc-config"
                        v-model="configurationToAdd.facture_description"
                        class="b-form-select-raduis"
                      ></b-textarea>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Réduction"
                      label-for="Coefficient"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="configurationToAdd.reduction"
                        required
                        type="number"
                        step="any"
                        min="0"
                        oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Type de réduction"
                      label-for="filiale-name"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="configurationToAdd.reduction_type"
                        required
                        :options="coef_type_reduction_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      :label="
                        configurationToAdd.coef_type == 'pourcentage'
                          ? 'Total de Coefficient'
                          : 'Total du Montant'
                      "
                      label-for="Coefficient"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="configurationToAdd.coef"
                        required
                        type="number"
                        step="any"
                        class="b-form-select-raduis"
                        :disabled="computedDisabledCoeffition"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Objet"
                      label-for="label"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="configurationToAdd.label"
                        required
                        class="b-form-select-raduis"
                        @input="checkErrorsForAdd"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div
                    v-for="(coeff, index) in configurationToAdd.coefs"
                    :key="index"
                  >
                    <div class="ModelCol mr-3">
                      <b-form-group
                        :label="
                          configurationToAdd.coef_type == 'pourcentage'
                            ? 'Coefficient'
                            : 'Montant'
                        "
                        label-for="Coefficient"
                        class="input-modal-champ mb-0 ml-0 pl-0 col-6"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="coeff.coef"
                          required
                          type="number"
                          step="any"
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Label"
                        label-for="label"
                        class="input-modal-champ mb-0 pl-0 col-6"
                      >
                        <!-- <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="coeff.label"
                          required
                          class="b-form-select-raduis"
                        ></b-form-input> -->
                        <b-textarea
                          autocomplete="off"
                          id="filiale-name"
                          v-model="coeff.label"
                          required
                          class="b-form-select-raduis"
                        ></b-textarea>
                      </b-form-group>
                      <b-form-group class="input-modal-champ mb-0">
                        <b-button
                          v-if="index > 0"
                          class="button-danger-style ml-1 float-left mt-4"
                          size="sm"
                          variant="danger"
                          @click="deleteCoefficient(index, 'add')"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-form-group>
                    </div>
                  </div>
                  <div>
                    <b-form-group
                      label=""
                      label-for=""
                      class="input-modal-champ mb-0"
                    >
                      <button
                        type="button"
                        @click="addNewcoeffition('add')"
                        class="ml-2 btn-add-anay-prop"
                      >
                        <font-awesome-icon
                          icon="plus"
                          class="icon-plus-ciston-config-dync"
                        />
                      </button>
                    </b-form-group>
                  </div>
                  <hr />
                  <div
                    v-if="
                      configurationToAdd.coef_type === 'pourcentage' ||
                        configurationToAdd.coef_type === 'prix unitaire'
                    "
                  >
                    <div class="d-flex justify-content-start text-dark mt-1">
                      <p><b>Formules:</b></p>
                    </div>
                    <div class="ModelCol mr-3">
                      <b-button
                        variant="success"
                        class="config-btn d-flex justify-content-start ml-2"
                        @click="addNewFormule('add')"
                        ><font-awesome-icon icon="plus" /> Ajouter une
                        formule</b-button
                      >
                      <div>
                        <b-form-group
                          v-if="configurationToAdd.formules.length > 1"
                          label=""
                          label-for=""
                          class="
                            input-modal-champ
                            mb-0
                            ml-2
                            d-flex
                            justify-content-start
                          "
                        >
                          <b-form-select
                            autocomplete="off"
                            id="and_or"
                            v-model="configurationToAdd.formule_types"
                            required
                            :options="formule_types_options"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group
                          label="Colonne"
                          label-for="colonne"
                          class="
                            input-modal-champ
                            mb-0
                            ml-2
                            d-flex
                            justify-content-start
                          "
                        >
                          <b-form-select
                            autocomplete="off"
                            :id="'condition_type'"
                            @input="CopyFields($event)"
                            :options="getNumeriqCulomn"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                      </div>
                      <div>
                        <div class="CopieMsg" v-if="isTextCopied">
                          Text copier
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(formu, index) in configurationToAdd.formules"
                      :key="index"
                    >
                      <div class="ModelCol mr-3">
                        <b-form-group
                          label="Formule"
                          label-for="formule"
                          class="input-modal-champ mb-0 col-lg-11"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="filiale-name"
                            v-model="formu.formule"
                            rows="3"
                            required
                            @keyup="
                              checkFormuleConfigFraisDynamic('add', index)
                            "
                            class="b-form-select-raduis"
                          ></b-form-input>
                          <em class="text-danger">{{ formu.error }} </em>
                        </b-form-group>

                        <b-form-group class="input-modal-champ mb-0 col-lg-1">
                          <b-button
                            class="button-danger-style mt-4"
                            size="sm"
                            variant="danger"
                            @click="deleteFormule(index, 'add')"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>
                        </b-form-group>
                        <hr />
                      </div>
                    </div>
                    <div v-if="configurationToAdd.formules.length">
                      <div class="d-flex justify-content-start text-dark mt-3">
                        <p>
                          <b class="mr-2">Formules Finale:</b
                          ><em>
                            {{ computedFormule(configurationToAdd) }}
                          </em>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <p class="d-flex justify-content-start text-dark">
                    <b> Condition de configuration</b>
                  </p>
                  <div class="ModelCol mr-3">
                    <b-button
                      variant="success"
                      class="config-btn d-flex justify-content-start ml-2"
                      @click="addNewCondition('add')"
                      ><font-awesome-icon icon="plus" /> Ajouter une
                      condition</b-button
                    >
                    <div>
                      <b-form-group
                        v-if="configurationToAdd.conditions.length > 1"
                        label=""
                        label-for="filiale-name"
                        class="
                          input-modal-champ
                          mb-0
                          ml-2
                          d-flex
                          justify-content-start
                        "
                      >
                        <b-form-select
                          autocomplete="off"
                          id="and_or"
                          v-model="configurationToAdd.condition_types"
                          required
                          :options="and_or_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div
                    v-for="(condition, index) in configurationToAdd.conditions"
                    :key="index"
                  >
                    <div class="ModelCol mr-3">
                      <p class="m-0" hidden>
                        {{ (condition.index = index) }}
                      </p>
                      <b-form-group
                        label="Colonne"
                        label-for="colonne"
                        class="input-modal-champ mb-0"
                      >
                        <select-simple-with-search
                          :value="condition.column"
                          :options="computedGetColumns"
                          placeholder="colonne"
                          @changeValue="
                            condition.column = $event;
                            condition.value = checkColomnConditionConfig(
                              condition.column
                            )
                              ? null
                              : condition.value;
                          "
                          label="value"
                          trackBy="key"
                          :title="conputedSelectedColumn(condition.column)"
                          noResult="Aucune Colonne trouvé."
                        />
                      </b-form-group>
                      <b-form-group
                        label="Operation"
                        label-for="Operation"
                        class="input-modal-champ mb-0"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="filiale-name"
                          v-model="condition.operation"
                          required
                          :options="operation_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group
                        v-if="
                          condition.operation != 'ISNULL' &&
                            condition.operation != 'ISNOTNULL'
                        "
                        label="Valeur"
                        label-for="Valeur"
                        class="input-modal-champ mb-0"
                      >
                        <select-simple-with-search
                          v-if="checkColomnConditionConfig(condition.column)"
                          :value="condition.value"
                          :options="
                            condition.column == 'organisme'
                              ? getListOrganismeObligee
                              : getProFilials
                          "
                          placeholder="valeur"
                          @changeValue="condition.value = $event"
                          label="name"
                          trackBy="name"
                          noResult="Aucun valeur trouvé."
                        />
                        <b-form-input
                          v-else
                          autocomplete="off"
                          v-model="condition.value"
                          required
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="input-modal-champ mb-0">
                        <b-button
                          class="button-danger-style mt-4"
                          size="sm"
                          variant="danger"
                          @click="deleteCondition(index, 'add')"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-form-group>
                    </div>
                    <hr />
                  </div>

                  <div
                    v-if="
                      configurationToAdd.filiale_id != null &&
                        configurationToAdd.date_debut != null
                    "
                  >
                    <div class="d-flex justify-content-start text-dark mt-1">
                      <p><b>Specifics:</b></p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <b-form-checkbox
                        v-model="checkAll"
                        switch
                        @change="changeAllCheckColumns"
                        >Tous</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="specific_master"
                        switch
                        @change="changeMastersFormmat"
                        >Master</b-form-checkbox
                      >
                      <b-form-group
                        label=""
                        label-for="search-add"
                        class="input-modal-champ mb-0"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="search"
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <br />
                    <b-form-group
                      label=""
                      v-slot="{ ariaDescribedby }"
                      class="d-block"
                    >
                      <div
                        class="custom-class-fields-av scroll-specifics-params"
                      >
                        <b-form-checkbox
                          v-for="(option, index) in computedSearchListSpecifics"
                          :key="index"
                          v-model="configurationToAdd.specifics"
                          :aria-describedby="ariaDescribedby"
                          :value="{ filiale_id: option.filiale_id }"
                          @change="checkErrorsForAdd(option)"
                        >
                          {{ option.name }}
                          <template v-if="specific_master && option.master">
                            <b-form-checkbox
                              v-for="(master, indexM) in option.societe_master"
                              :key="'M' + indexM"
                              v-model="configurationToAdd.specifics"
                              :aria-describedby="ariaDescribedby"
                              :value="{ filiale_id: master.id }"
                              @change="checkErrorsForAdd"
                            >
                              {{ master.name }}</b-form-checkbox
                            >
                          </template>
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="actionModel">
                <div class="messageError" v-if="errorConfigToAdd">
                  <div class="error">
                    {{ errorConfigToAdd }}
                  </div>
                </div>
                <b-button
                  type="submit"
                  class="button-valide-style"
                  :disabled="getIsLoadingConfigFraisTh"
                >
                  <!-- !validateToAddconf ||  -->
                  <span>
                    Valider
                    <div v-if="getIsLoadingConfigFraisTh" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
      <b-modal
        id="addConfigurationFamilleFraisModal"
        ref="addConfigurationFamilleFraisModal"
        title="Ajouter une famille"
        :hide-footer="true"
        :hide-header="true"
        no-close-on-backdrop
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap "
      >
        <div class="hader mb-2">
          <div class="titleSetting">Ajouter une famille</div>
          <div
            class="iconClose"
            @click.prevent="
              hideModalFamille('addConfigurationFamilleFraisModal')
            "
          >
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="hendleSubmitFamille"
              class="form-modal-custom-style"
            >
              <b-form-group
                label="Nom"
                label-for="nom"
                class="input-modal-champ"
              >
                <b-form-input
                  id="nom"
                  v-model="familleToAdd.name"
                  required
                ></b-form-input>
              </b-form-group>

              <div class="actionModel mt-3">
                <div class="messageError">
                  <div v-if="getErrorFamilleConfig" class="error">
                    <ul v-if="Array.isArray(getErrorFamilleConfig)">
                      <li
                        v-for="(e, index) in getErrorFamilleConfig"
                        :key="index"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ getErrorFamilleConfig }}</div>
                  </div>
                </div>
                <b-button
                  type="submit"
                  class="button-valide-style"
                  :disabled="getLoadingFamilleConfig"
                >
                  <span>
                    Valider
                    <div v-if="getLoadingFamilleConfig" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
      <b-modal
        ref="addtvaModalConfigFrais"
        id="addtvaModalConfigFrais"
        no-close-on-backdrop
        :hide-footer="true"
        @hidden="resetModalTVA"
        title="Ajouter tva"
        modal-class="cutsom-modal-bootstrap"
      >
        <b-form-group label="Tva" label-for="unite" class="input-modal-champ">
          <b-form-input
            id="tva"
            v-model="tvaToAdd"
            required
            type="number"
            autocomplete="off"
            min="0"
            oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
          ></b-form-input>
        </b-form-group>
        <div class="error-message d-flex justify-content-center">
          <div class="error">
            <div>{{ errorTvaUnite }}</div>
          </div>
        </div>
        <div class="form-modal-custom-style mt-2">
          <div class="actionModel">
            <b-button class="button-valide-style" @click="addTav('tva')">
              <span>
                Valider
                <div v-if="loadingTvaUnite" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <div class="sub-menu-simple">
      <div class="nav-bar">
        <div class="menu-links">
          <div
            class="menu-link"
            @click="clickMenu('config')"
            v-if="checkPermission('GLCTH')"
          >
            <router-link class="menu-item" to="/setting/gestion-configuration">
              Configuration
            </router-link>
          </div>
          <div
            class="menu-link"
            @click="clickMenu('famille')"
            v-if="checkPermission('GDFC')"
          >
            <router-link
              class="menu-item"
              to="/setting/gestion-configuration/famille"
            >
              Famille Support
            </router-link>
          </div>
        </div>
      </div>

      <div class="body">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import SelectSimpleWithSearch from '../../component/selectSimpleWithSearch.vue';
export default {
  data() {
    return {
      loadingFiliale: false,
      specific_master: false,
      errorConfigToAdd: null,
      tvaToAdd: null,
      errorTvaUnite: null,
      loadingTvaUnite: false,
      familleToAdd: { name: null },
      validateToAddconf: false,
      selectedMenu: 'config',
      loading: false,
      error: null,
      checkAll: false,
      acheteur_type_list: [
        { id: 'frais', name: 'frais' },
        { id: "bureau d'etude", name: "Bureau d'etude" }
      ],
      formule_types_options: [
        { text: 'Min', value: 'min' },
        { text: 'Max', value: 'max' }
      ],
      coef_type_reduction_options: [
        { text: 'Pourcentage', value: 'pourcentage' },
        { text: 'Montant fix', value: 'montant fix' },
        { text: 'Prix unitaire', value: 'prix unitaire' }
      ],
      and_or_options: [
        { text: 'OU', value: 'or' },
        { text: 'ET', value: 'and' }
      ],
      isTextCopied: false,
      operation_options: [
        {
          text: 'Egale à',
          value: '=='
        },
        {
          text: 'Pas égale à',
          value: '!='
        },
        {
          text: 'Supérieur à',
          value: '>'
        },
        {
          text: 'Inférieur à',
          value: '<'
        },
        {
          text: 'Superieur ou égale à',
          value: '>='
        },
        {
          text: 'Inferieur ou égale à',
          value: '<='
        },
        {
          text: 'Contient',
          value: 'str_contains'
        },
        {
          text: 'ne contient pas',
          value: '!str_contains'
        },
        {
          text: 'est null',
          value: 'ISNULL'
        },
        {
          text: "n'est pas null",
          value: 'ISNOTNULL'
        }
      ],
      configurationToAdd: {
        commercials: [],
        main_family: 'support',
        filiale_id: null,
        grouped: 'dossier',
        group_by: null,
        facture_group_by: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null
          }
        ],
        conditions: [],
        specifics: [],
        formules: [{ formule: null, error: null }],
        acheteur_type: 'frais',
        description: null,
        facture_description: null,
        famillies: [],
        payment_call: null
      },

      search: null
    };
  },
  computed: {
    ...mapGetters([
      'getConfigurationError',
      'getErrorForSpecific',
      'getProFilials',
      'getIsLoadingConfigFraisTh',
      'getNumeriqCulomn',
      'getErrorFamilleConfig',
      'getLoadingFamilleConfig',
      'getColumns',
      'getListFamilleConfigFrias',
      'getLoadingFamilleConfig',
      'getAllTvas',
      'checkPermission',
      'getListOrganismeObligee'
    ]),
    computedCommercial() {
      return this.getProFilials.filter(i => i.type == 'particulier');
    },
    computedNotCommercial() {
      return this.getProFilials.filter(i => i.type != 'particulier');
    },
    computedCheckSocieteSupport() {
      return (
        this.configurationToAdd.filiale_id &&
        this.getProFilials.filter(
          i => i.id == this.configurationToAdd.filiale_id
        ).length &&
        this.getProFilials.filter(
          i => i.id == this.configurationToAdd.filiale_id
        )[0].is_societe_support == 0 &&
        this.configurationToAdd.main_family == 'support'
      );
    },
    checkColomnConditionConfig() {
      return function(value) {
        return (
          value &&
          (value == 'installeur' ||
            value == 'organisme' ||
            value == 'regie' ||
            value == 'sous_regie' ||
            value == 'super_regie')
        );
      };
    },
    conputedSelectedColumn() {
      return function(key) {
        return key != null ? this.getColumns[key] : '';
      };
    },
    computedGetColumns() {
      return Object.keys(this.getColumns).map(i => {
        return { value: this.getColumns[i], key: i };
      });
    },
    computedSelectAllFamilles() {
      return function(familles) {
        if (familles.length == this.getListFamilleConfigFrias.length) {
          return true;
        }
        return false;
      };
    },
    computedSelectAllComercials() {
      return function(commercial) {
        if (commercial.length == this.computedCommercial.length) {
          return true;
        }
        return false;
      };
    },
    computedFormule() {
      return function(data) {
        if (data?.formules?.length) {
          let formule = '';
          formule =
            formule + data?.formules?.length == 1
              ? ''
              : data.formule_types + '(';
          data.formules.forEach((element, index) => {
            let elemt =
              element.formule == null ? '' : '(' + element.formule + ')';
            index < data.formules.length - 1
              ? elemt == ''
                ? (formule = formule + '')
                : (formule = formule + elemt + ',')
              : (formule = formule + elemt);
          });
          data?.formules?.length == 1
            ? (formule = formule + '')
            : (formule = formule + ')');
          Object.keys(this.getNumeriqCulomn).forEach(key => {
            formule = formule.replaceAll('$' + key, this.getNumeriqCulomn[key]);
          });

          return formule;
        }
        return '';
      };
    },
    getComputedConfugiration() {
      return this.configurationToAdd.coefs;
    },
    computedDisabledCoeffition() {
      if (this.configurationToAdd.coefs.length > 0) {
        return true;
      }
      return false;
    },
    computedFormatFilialList() {
      return this.getProFilials
        .filter(i => i.type != 'particulier')
        .map(item => ({
          ...item,
          filiale_id: item.id
        }));
    },
    computedSearchListSpecifics() {
      let table = this.specific_master
        ? this.computedFormatFilialList.filter(
            i => i.master == this.specific_master
          )
        : this.computedFormatFilialList;
      if (this.search != null) {
        table = table.filter(item =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.master == true) {
        table = table.filter(item => item.master == true);
      }

      return table;
    }
  },
  methods: {
    ...mapActions([
      'initErrors',
      'addNewConfiguration',
      'addNewFailleConfigFrais',
      'initErrorsFamillyConfig',
      'checkPeriod',
      'fetchAllFammilleConfig',
      'getTvas',
      'addUniteTva',
      'checkFormuleConfigFraisDynamicMethod',
      'getAllDataForFilterObligee',
      'getAllProFilial'
    ]),

    SelectAllFamilles(check) {
      if (check == true) {
        this.configurationToAdd.famillies = this.getListFamilleConfigFrias;
      } else {
        this.configurationToAdd.famillies = [];
      }
    },
    SelectAllCommercials(check) {
      if (check == true) {
        this.configurationToAdd.commercials = this.computedCommercial;
      } else {
        this.configurationToAdd.commercials = [];
      }
    },
    initialFamilly() {
      if (this.configurationToAdd.main_family == false) {
        this.configurationToAdd.main_family = 'support';
      }
    },
    changeInitialFamily() {
      if (this.configurationToAdd.main_family == false) {
        this.configurationToAdd.main_family = 'support';
      }
    },
    hideModalFamille(ref) {
      this.$refs[ref].hide();
      this.familleToAdd = { name: null };
    },
    changeState() {
      if (this.configurationToAdd.grouped == false) {
        this.configurationToAdd.grouped = 'dossier';
      }
    },
    stateInitial() {
      if (this.configurationToAdd.grouped == false) {
        this.configurationToAdd.grouped = 'dossier';
      }
    },
    async checkFormuleConfigFraisDynamic(type, index) {
      let value =
        type === 'add'
          ? this.configurationToAdd.formules[index].formule
          : this.configurationToUpdate.formules[index].formule;
      type === 'add'
        ? (this.configurationToAdd.formules[index].error = null)
        : (this.configurationToUpdate.formules[index].error = null);
      const response = await this.checkFormuleConfigFraisDynamicMethod({
        formule: value
      });
      if (response && response.success == false) {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = response.error;
        } else {
          this.configurationToUpdate.formules[index].error = response.error;
        }
      } else {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = null;
        } else {
          this.configurationToUpdate.formules[index].error = null;
        }
      }
    },
    async addTav(type) {
      if (this.tvaToAdd > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.tvaToAdd === '' || this.tvaToAdd === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: parseFloat(this.tvaToAdd),
          type: type
        });
        if (response.success) {
          this.getAllTvas.push(response.response);
          this.$refs['addtvaModalConfigFrais'].hide();
          this.resetModalTVA();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModalConfigFrais'].hide();
          this.resetModalTVA();
          this.loadingTvaUnite = false;
        }
      }
    },
    addNewFormule(type) {
      if (type === 'add') {
        this.configurationToAdd.formules.push({
          formule: null,
          error: null
        });
      } else {
        this.configurationToUpdate.formules.push({
          formule: null,
          error: null
        });
      }
    },
    deleteCoefficient(index, type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.splice(index, 1);
      } else {
        this.configurationToUpdate.coefs.splice(index, 1);
      }
    },
    async hendleSubmitFamille() {
      const response = await this.addNewFailleConfigFrais(this.familleToAdd);
      if (response) {
        this.familleToAdd.name = null;
        this.$refs['addConfigurationFamilleFraisModal'].hide();
        // this.hideModalFamille('addConfigurationFamilleFraisModal');
      }
    },
    clickMenu(menu) {
      this.selectedMenu = menu;
    },
    openModalConfig() {
      this.initErrors();
      this.checkAll = true;
      this.specific_master = false;
      this.changeAllCheckColumns();
      this.fetchAllFammilleConfig({});
    },
    changeMastersFormmat() {
      if (this.specific_master == true) {
        this.checkAll = false;
        this.configurationToAdd.specifics = [];
      }
    },
    changeAllCheckColumns() {
      let table = [];
      if (this.checkAll) {
        if (this.specific_master) {
          this.computedNotCommercial
            .filter(i => i.master)
            .map(item => {
              table.push({
                filiale_id: item.id
              });
              item.societe_master.map(m =>
                table.push({
                  filiale_id: m.id
                })
              );
            });
        } else {
          table = this.computedNotCommercial.map(item => ({
            filiale_id: item.id
          }));
        }
      } else {
        if (this.specific_master) {
          let masterIds = this.computedNotCommercial
            .filter(i => i.master)
            .map(i => i.societe_master)
            .flat()
            .map(i => i.id);
          table = this.computedNotCommercial
            .filter(i => !masterIds.includes(i.id) && !i.master)
            .map(i => ({ filiale_id: i.id }));
        }
      }

      this.configurationToAdd.specifics = table;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.errorConfigToAdd = null;
      this.search = null;
      this.configurationToAdd = {
        commercials: [],
        main_family: 'support',
        filiale_id: null,
        grouped: 'dossier',
        group_by: null,
        facture_group_by: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null
          }
        ],
        conditions: [],
        specifics: [],
        formules: [{ formule: null, error: null }],
        acheteur_type: 'frais',
        description: null,
        facture_description: null,
        famillies: [],
        payment_call: null
      };

      this.initErrors();
      this.initErrorsFamillyConfig();
    },
    resetModalTVA() {
      this.tvaToAdd = null;
      this.errorTvaUnite = null;
    },
    async handleSubmitConfiguration() {
      this.errorConfigToAdd = null;
      if (
        this.configurationToAdd.main_family == 'support' &&
        this.configurationToAdd.famillies.length == 0
      ) {
        this.errorConfigToAdd = 'sélectionne au moins une famille';
      } else if (
        this.configurationToAdd.main_family == 'commercial' &&
        this.configurationToAdd.commercials.length == 0
      ) {
        this.errorConfigToAdd = 'sélectionne au moins un commercial';
      } else {
        this.configurationToAdd.grouped =
          this.configurationToAdd.grouped == 'dossier' ? 'non' : 'oui';
        this.configurationToAdd.payment_call =
          this.configurationToAdd.payment_call == true ? 'oui' : 'non';
        this.configurationToAdd.reduction = parseFloat(
          this.configurationToAdd.reduction
        );
        const response = await this.addNewConfiguration(
          this.configurationToAdd
        );
        if (response?.success) {
          this.hideModal('addConfigurationFraisModal');
        }
      }
    },
    async checkErrorsForAdd(option) {
      if (option && option.master) {
        let testTable = this.configurationToAdd.specifics.map(
          i => i.filiale_id
        );
        option.societe_master.forEach(i => {
          if (!testTable.includes(i.id)) {
            this.configurationToAdd.specifics.push({ filiale_id: i.id });
          }
        });
      }
      this.checkAll =
        this.configurationToAdd.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;

      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id,
        date_debut:
          this.configurationToAdd.date_debut != null
            ? moment(this.configurationToAdd.date_debut, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_debut,
        date_fin:
          this.configurationToAdd.date_fin != null
            ? moment(this.configurationToAdd.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_fin,
        specific_ids: [],
        acheteur_id: this.configurationToAdd.acheteur_id,
        coef_type: this.configurationToAdd.coef_type
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToAddconf = true;
          this.initErrors();
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.configurationToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToAdd.date_debut)
      );
    },
    addNewcoeffition(type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.push({
          coef: 0,
          label: null
        });
      } else {
        this.configurationToUpdate.coefs.push({
          coef: 0,
          label: null
        });
      }
    },
    deleteFormule(index, type) {
      if (type === 'add') {
        this.configurationToAdd.formules.splice(index, 1);
      } else {
        this.configurationToUpdate.formules.splice(index, 1);
      }
    },
    addNewCondition(type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      } else {
        this.configurationToUpdate.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      }
    },
    deleteCondition(index, type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.splice(index, 1);
      } else {
        this.configurationToUpdate.conditions.splice(index, 1);
      }
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    }
  },
  watch: {
    getComputedConfugiration: {
      handler: function() {
        if (this.configurationToAdd.coefs.length > 0) {
          let sum = 0;
          this.configurationToAdd.coefs.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToAdd.coef = sum;
        }
      },
      deep: true
    }
  },
  components: {
    DatePicker,
    Card: () => import('@/views/component/card.vue'),
    SelectSimpleWithSearch
  },
  async mounted() {
    if (this.$route.path === '/setting/gestion-configuration/famille') {
      this.selectedMenu = 'famille';
    } else {
      this.selectedMenu = 'config';
    }
    this.getTvas();
    this.getAllDataForFilterObligee();
    this.loadingFiliale = true;
    const response = await this.getAllProFilial();
    if (response) {
      this.loadingFiliale = false;
    }
  }
};
</script>
<style lang="scss">
.mt-21 {
  margin-left: -67px;
}
.btn-add-anay-prop {
  height: 25px;
  width: 25px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
  float: left;
  margin-top: 30px;
}
.btn-add-anay-prop:hover {
  background-color: #087530;
}
.icon-plus-ciston-config-dync {
  color: #fff;
  height: 17px;
  width: 17px;
  margin-top: 3px;
}
.custom-class-fields-av {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
.custom-class-fields-av {
  .custom-control {
    width: 46%;
  }
}
.famillies-list-label {
  legend {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a !important;
    margin-top: 8px;
    margin-bottom: 1px;
  }
}
.CopieMsg {
  margin-left: 50px;
  color: #4d4bac;
  font-weight: 12px;
}
</style>
